<template>
  <v-navigation-drawer color="white" permanent clipped-left app>
    <v-app-bar flat>
      <v-img src="@/assets/logo.png" contain justify="center" max-height="50" max-width="200" class="my-auto">
      </v-img>
    </v-app-bar>
    <v-divider></v-divider>
    <v-card height="75%" flat tile class="overflow-y-auto pa-0">
      <v-list dense>
        <v-list-item @click="navigate('/')">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Home </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="navigate('/showManagement')">
          <v-list-item-icon>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Show </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="navigate('/subscribeManagement')">
          <v-list-item-icon>
            <v-icon>mdi-package-down</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Subscribe </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </v-card>
    <template v-slot:append v-if="userProfile.data().telepresence">
      <v-divider></v-divider>
      <!-- <org-group-list></org-group-list> -->
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import orgGroupList from "../orgGroupList.vue";
import router from "@/router";
export default {
  components: { orgGroupList },
  name: "adminDrawer",
  data() {
    return {
      selectedItem: 1,
      category: null,
      availableUsers: 0
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredUsers",
      "userRoles",
      "userId",
      "userProfile",
      "userGroups",
    ]),
    filteredUsers() {
      let users = [];
      this.allRegisteredUsers.forEach((user) => {
        if (
          user.data().groups.includes(this.category) &&
          user.id !== this.userId
        ) {
          users.push(user);
        }
      });
      return users;
    },
    getAvailableUsers() {
      let users = [];
      this.filteredUsers.forEach((user) => {
        if (user.data().onlineStatus === "Available") {
          users.push(user.id);
        }
      });
      return users;
    },
  },
  methods: {
    getColor(status) {
      if (status === "Unavailable") return "error";
      if (status === "Busy") return "amber";
      if (status === "Available") return "success";
    },
    initiateCall(userInfo, userId, userRole) {
      this.$store.dispatch("initiateCall", {
        userInfo: userInfo,
        userId: userId,
        userRole: userRole,
      });
    },
    navigate(link) {
      if (router.currentRoute.path === "/liveCall") {
        window.open(link, "_blank");
      } else {
        router.push(link);
      }
    },
  },
};
</script>