import * as fb from "../../firebase";
import moment from "moment";
import crypto from "crypto"
export default {
  state: {
    assignedKiosks: [],
    kioskLicenses: [],
    userLicenses: [],
    licenseError: false,
    licenseExpiry: false
  },
  getters: {
    assignedKiosks: state => state.assignedKiosks,
    kioskLicenses: state => state.kioskLicenses,
    userLicenses: state => state.userLicenses,
    getLicenseError: state => state.licenseError,
    getLicenseExpiry: state => state.licenseExpiry
  },
  mutations: {
    setAssignedKiosks(state, kiosks) {
      state.assignedKiosks = kiosks
    },
    setKioskLicenses(state, license) {
      state.kioskLicenses = license
    },
    clearKioskLicenses(state) {
      state.kioskLicenses = []
    },
    setUserLicenses(state, license) {
      state.userLicenses = license
    },
    setLicenseError(state, status) {
      state.licenseError = status
    },
    setLicenseExpiry(state, status) {
      state.licenseExpiry = status
    }
  },
  actions: {
    // Generate Licenses for Kiosk 

    generateKioskLicenses({ dispatch }, kioskInfo) {
      for (let i = 0; i < kioskInfo.noOfKiosks; i++) {

        let license = 'ARS-' + crypto.randomBytes(25).toString("hex").slice(0, 4) + '-'
          + crypto.randomBytes(25).toString("hex").slice(0, 4)
          + '-' + crypto.randomBytes(25).toString("hex").slice(0, 4) + '-' + crypto.randomBytes(25).toString("hex").slice(0, 4)

        let kioskName = kioskInfo.location + '-' + Math.floor((new Date()).getTime() / 1000.0) + i;

        fb.kioskLicenseCollection.doc(license.toUpperCase()).set({
          clientName: kioskInfo.clientName,
          showName: kioskInfo.name,
          licenseNumber: license.toUpperCase(),
          enabled: true,
          name: kioskName,
          location: kioskInfo.location,
          inUse: 'Unassigned',
          kioskStatus: 'Unavailable',
          startDate: moment(kioskInfo.startDate).subtract(1, "d")._d,
          expiryDate: moment(kioskInfo.endDate).add(1, "d")._d
        }).then(response => {
          console.log(response)
        })
      }
    },

    // Generate User License
    generateUserLicenses({ dispatch }, licenses) {
      for (let i = 0; i < licenses.noOfLicensce; i++) {

        let license = 'ARS-' + crypto.randomBytes(25).toString("hex").slice(0, 4) + '-'
          + crypto.randomBytes(25).toString("hex").slice(0, 4)
          + '-' + crypto.randomBytes(25).toString("hex").slice(0, 4) + '-' + crypto.randomBytes(25).toString("hex").slice(0, 4)

        fb.userLicenseCollection.doc(license.toUpperCase()).set({
          licenseNumber: license.toUpperCase(),
          enabled: true,
          inUse: false,
          startDate: moment(licenses.startDate).subtract(1, "d")._d,
          expiryDate: moment(licenses.endDate).add(1, "d")._d,
          clientName: licenses.clientName,
          showName: licenses.showName,
          status: 'Pending',
          userStatus: 'Unavailable',
        }).then(response => {
          console.log(response)
        })
      }
    },

    verifyUserLicense({ commit }, licenseNumber) {
      return new Promise((resolve, reject) => {
        fb.userLicenseCollection.doc(licenseNumber).get().then(license => {
          if (moment(new Date()).isBetween(
            moment(license.data().startDate.toDate()).format("L"),
            moment(license.data().expiryDate.toDate()).format("L")) && !license.data().inUse) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
          .catch(error => {
            reject(error);
          })
      })
    },


    pullKioskLicenses({ commit }, clientName) {
      fb.kioskLicenseCollection.where('clientName', '==', clientName).onSnapshot(response => {
        commit('setKioskLicenses', response.docs)
      })
    },

    pullUserLicenses({ commit, dispatch }, clietName) {
      fb.userLicenseCollection.where('clientName', '==', clietName).onSnapshot(response => {
        commit('setUserLicenses', response.docs)
      })
    },

    updateUserLicenseWithInfo({ commit }, userInfo) {
      fb.userLicenseCollection.doc(userInfo.licenseNumber).update({
        name: userInfo.name,
        email: userInfo.email,
        status: 'Invited'
      })
    },

    updateUserLicenseToRegistered({ commit }, license) {
      fb.userLicenseCollection.doc(license).update({
        status: 'Registered'
      })
    },

    licenseAvailable({ commit }, licenseNumber) {
      fb.kioskLicenseCollection.doc(licenseNumber).update({
        inUse: 'Revoked'
      }).then(() => {
        fb.kioskLicenseCollection.doc(licenseNumber).update({
          inUse: 'Unassigned'
        })
      })
    }
  }
}