<template>
    <v-navigation-drawer color="white" permanent clipped-left app>
        <v-app-bar flat>
            <v-img src="@/assets/logo.png" contain justify="center" max-height="50" max-width="200" class="my-auto">
            </v-img>
        </v-app-bar>
        <v-divider></v-divider>
        <v-card height="75%" flat tile class="overflow-y-auto pa-0">
            <v-list dense>
                <v-list-item @click="navigate('/')">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Home </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <!-- <v-list-item @click="navigate('/clientManagement')">
                    <v-list-item-icon>
                        <v-icon>mdi-account-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Clients </v-list-item-title>
                </v-list-item> -->
                <!-- <v-divider></v-divider> -->
                <v-list-item @click="navigate('/billing')">
                    <v-list-item-icon>
                        <v-icon>mdi-receipt</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Billing </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="navigate('/createSuperAdmin')">
                    <v-list-item-icon>
                        <v-icon>mdi-shield-crown-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Super Admin </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
            </v-list>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
import router from '../../../router';
export default {
    name: "adminDrawer",
    data() {
        return {
            selectedItem: 1,
        };
    },
    methods: {
        navigate(link) {
            if (router.currentRoute.path === "/liveCall") {
                window.open(link, "_blank");
            } else {
                router.push(link);
            }
        }
    }
};

</script>