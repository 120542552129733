
module.exports = {
  firebase: {
    apiKey: "AIzaSyCzZpe8yCZRUCSX_G37m3X-YHgDIBmQg9M",
    authDomain: "telepresence-service.firebaseapp.com",
    databaseURL: "https://telepresence-service-default-rtdb.firebaseio.com",
    projectId: "telepresence-service",
    storageBucket: "telepresence-service.appspot.com",
    messagingSenderId: "858354346846",
    appId: "1:858354346846:web:aea1a7edbbbd8a10eb7dcf",
    measurementId: "G-8MJ8HGBHC6"
  }
}

