<template>
  <v-navigation-drawer color="white" permanent clipped-left app>
    <v-app-bar flat>
      <v-img src="@/assets/logo.png" contain justify="center" max-height="50" max-width="200" class="my-auto">
      </v-img>
    </v-app-bar>
    <v-divider></v-divider>

    <v-list dense>
      <org-group-list></org-group-list>
      <v-divider></v-divider>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import orgGroupList from "../orgGroupList.vue";
import router from "@/router";
export default {
  name: "adminDrawer",
  components: { orgGroupList },
  data() {
    return {
      selectedItem: 1,
      category: null
    };
  },
  methods: {
    navigate(link) {
      if (router.currentRoute.path === "/liveCall") {
        window.open(link, "_blank");
      } else {
        router.push(link);
      }
    }
  },
  computed: {
    ...mapGetters(["allRegisteredUsers", "userId", "userProfile"]),
  },
};
</script>