<template>
  <v-dialog width="35%" v-model="show">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        elevation="0"
        color="primary"
        text
        class="px-6 py-0"
      >
        <v-icon left> mdi-plus </v-icon>
        Conference Call
      </v-btn>
    </template>
    <v-card width="100%" height="100%">
      <v-card-title class="justify-center">Conference Call</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="pt-5">
          <v-col cols="6" align="center">
            <v-btn
              width="80%"
              color="primary"
              class="white--text"
              outlined
              @click="
                conferenceCall(
                  { name: groupName },
                  getGroupInfo,
                  groupName,
                  'Pull'
                )
              "
            >
              <v-icon left>mdi-video</v-icon>
              Pool Call
            </v-btn>
          </v-col>
          <v-col align="center">
            <v-btn
              width="80%"
              color="primary"
              class="white--text"
              @click="
                conferenceCall(
                  { name: groupName },
                  getGroupInfo,
                  groupName,
                  'Group'
                )
              "
            >
              <v-icon left>mdi-video</v-icon>
              Group Call
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import router from "@/router";
export default {
  name: "conferenceCallDialog",
  data() {
    return {
      show: false,
    };
  },
  props: {
    groupName: String,
  },
  methods: {
    conferenceCall(userInfo, userId, userRole, callType) {
      this.show = false;
      if (
        !this.userProfile.data().onCall ||
        router.currentRoute.path === "/liveCall"
      ) {
        this.$store.dispatch("requestCall", {
          userInfo: userInfo,
          userId: userId,
          userRole: userRole,
          callType: callType,
        });
      } else {
        this.$store.commit("setCallStatusDialog", {
          show: true,
          message: "You are already on a Call.",
        });
      }
    },
  },
  computed: {
    ...mapGetters(["userProfile", "userGroups", "userId"]),
    getGroupInfo() {
      let users;
      console.log('Group Info: ', this.userGroups, this.groupName)
      users = this.userGroups.find((grp) => grp.id === this.groupName).users;
      return users.filter((usr) => usr !== this.userId);
    },
  },
};
</script>