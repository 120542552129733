import * as fb from '../../firebase/index.js'
import router from "../../router";
import axios from 'axios';
import crypto from "crypto"
export default {
    state: {

        callStatusDialog: {},
        meetingUrl: {},
        userCallStatus: false,
        audio: new Audio("https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"),
        callEndTimer: 0,
        count: 0,
        incomingCallHistory: [],
        outgoingCallHistory: [],
        callInitiator: undefined,
    },
    getters: {

        getMeetingUrl: state => state.meetingUrl,
        getUserCallStatus: state => state.userCallStatus,
        getCallStatusDialog: state => state.callStatusDialog,
        getCount: state => state.count,
        getIncomingCallingHistory: state => state.incomingCallHistory,
        getOutgoingCallingHistory: state => state.outgoingCallHistory
    },
    mutations: {
        setCount(state, value) {
            state.count = value
        },
        setUserCallStatus(state, status) {
            state.userCallStatus = status
        },
        setMeetingUrl(state, urlObj) {
            state.meetingUrl = urlObj
        },
        setCallStatusDialog(state, obj) {
            state.callStatusDialog = obj
        },

        closeOutgoingCallDialog(state) {
            state.outgoingCallDialog = { show: false }
        },
        closeCallStatusDialog(state) {
            state.callStatusDialog = { show: false }
        },
        setIncomingCallingHistory(state, call) {
            state.incomingCallHistory.push(call)
        },
        setOutgoingCallingHistory(state, call) {
            state.outgoingCallHistory.push(call)
        }
    },
    actions: {
        pullCallHistory({ commit, state, getters }) {
            // query telepresence and get top 10 result
            //state.callingHistory = []
            console.log('Pull Call History called')
            if (getters.getIncomingCallingHistory.length === 0 && getters.getOutgoingCallingHistory.length === 0) {
                fb.telepresenceCollection.where("callInitiator.name", "==", getters.userProfile.data().name).where("callStatus", "in", ["Ended", "Cancelled", "Declined"]).limit(10).onSnapshot(incomingCall => {
                    state.outgoingCallHistory = []
                    incomingCall.forEach(call => {
                        //console.log('Outgoing Call Logs', call.data())
                        commit('setOutgoingCallingHistory', call.data())
                    })
                })

                fb.telepresenceCollection.where("calledUser", "==", getters.userProfile.data().name).where("callStatus", "in", ["Ended", "Cancelled", "Declined"]).limit(10).onSnapshot(incomingCall => {
                    state.incomingCallHistory = []
                    incomingCall.forEach(call => {
                        //console.log('Incoming Call Logs', call.data())
                        commit('setIncomingCallingHistory', call.data())
                    })
                })
            }
        },
        endExistingCalls({ dispatch, getters }, meetings) {
            console.log('Meetingds : ', meetings)
            meetings.forEach(meetingId => {
                fb.telepresenceCollection.doc(meetingId).get().then(meetings => {
                    let index = getters.getOutgoingCall.findIndex(
                        (meeting) => meeting === meetingId
                    );
                    getters.getOutgoingCall.splice(index, 1);
                    console.log(' Existing Meetings: ', meetings.data())
                    if (meetings.data().callStatus !== 'Initiated') {
                        if (meetings.data().requestedUser === null || meetings.data().requestedUser.length === 0) {
                            dispatch("endCallRequest", meetingId)
                        } else {
                            if (meetings.data().source) {
                                fb.telepresenceCollection.doc(meetingId).update({
                                    source: false
                                })
                            } else {
                                fb.telepresenceCollection.doc(meetingId).update({
                                    callStatus: 'Ended',
                                    requestedUser: null
                                })
                            }

                        }
                    }
                })
            })
        },
        stopListener({ getters }, meet) {
            console.log('Meet: ', meet)
            fb.telepresenceCollection
                .doc(meet).get().then(call => {
                    console.log('Call: ', call)
                    if (!call.data().source) {
                        fb.telepresenceCollection.doc(meet).update({
                            callStatus: 'Ended',
                            requestedUser: null,
                        })
                    } else {
                        fb.telepresenceCollection.doc(meet).update({
                            requestedUser: null,
                        })
                    }
                })
        },
        pullMeetingCodeDetails({ commit }, code) {

            let array = []
            return new Promise((resolve, reject) => {
                fb.telepresenceCollection.where("meetingCode", "==", code).get().then(calls => {
                    calls.forEach(call => {
                        console.log(call.data())
                        array.push(call.data())
                    })
                    resolve(array)
                })
            })
        },

        endCall({commit, dispatch, getters}){
            router.push("/");
            dispatch("setUserOffCall");
            commit("setMeetingCode", null);
            commit("setIncomingCallCount", 0);
            if (getters.getMeetingUrl.initiator === getters.userProfile.data().name) {
              // Stop listing to that event
              getters.getCallInitiateListener();
              dispatch("endExistingCalls", getters.getOutgoingCall);
            } else {
              //this.$store.dispatch("endExistingCalls", this.getOutgoingCall);
              dispatch("stopListener", getters.getMeetingUrl.meetingId);
            }
        },

        sampleAPI({ commit }){
            axios.post('/telepresenceRequest').then(response => {
                console.log(response)
            })
        }
    }

}