<template>
  <div>
    <navBar></navBar>
    <super-admin-drawer v-if="userProfile.data().userRole.toLowerCase() === 'super admin'"></super-admin-drawer>
    <adminDrawer v-else-if="userProfile.data().userRole.toLowerCase() === 'admin'"></adminDrawer>
    <generalDrawer v-else></generalDrawer>
  </div>
</template>

<script>
import navBar from "./navBar/navBar";
import adminDrawer from "./navDrawer/adminDrawer";
import generalDrawer from "./navDrawer/generalDrawer";
import { mapGetters } from "vuex";
import SuperAdminDrawer from './navDrawer/superAdminDrawer.vue';



export default {
  name: "navigation",
  components: {
    navBar,
    adminDrawer,
    generalDrawer,
    SuperAdminDrawer
  },
  computed: {
    ...mapGetters(["userProfile"]),
  },
};
</script>