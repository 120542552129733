<template>
  <v-dialog v-model="getLicenseError" max-width="45%" persistent>
    <v-card>
      <v-card-title class="justify-center"> Licensce Already In Use </v-card-title>
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          class="mx-10"
          @click="$store.commit('setLicenseError', false)"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  name: "licenseError",
  data() {
    return {
      show: null,
    };
  },
  components: {},
  methods: {},
  computed: {
    ...mapGetters(["getLicenseError"])
  },
  mounted() {},
};
</script>