import * as fb from '@/firebase/index.js'

export default {
  state: {
    snackBar: {},
    appData: {},
    announcement: {},
    dataLoaded: false,

    // UI Dimensions
    dimensions: {},
    windowSize: '',
    dialogDimensions: {},
    dataTableDimensions: {}
  },
  getters: {
    snackBar: state => state.snackBar,
    navBar: state => state.navBar,
    appData: state => state.appData,
    announcement: state => state.announcement,
    dataLoaded: state => state.dataLoaded,

    windowSize: state => state.windowSize,
    windowDimensions: state => state.dimensions,
    dialogDimensions: state => state.dialogDimensions,
    dataTableDimensions: state => state.dataTableDimensions
  },
  mutations: {
    setSnackBar(state, snackBar) {
      state.snackBar = snackBar;
    },
    setAppData(state, appData) {
      state.appData = appData;
    },
    setAnnouncement(state, announcement) {
      state.announcement = announcement;
    },
    setDataLoaded(state, status) {
      state.dataLoaded = status
    },

    setDimensions(state, obj){
      state.dimensions = obj
    },
    setWindowSize(state, size){
      state.windowSize = size
    },  
    setDialogDimensions(state, obj) {
      state.dialogDimensions = obj
    },
    setDataTableDimensions(state, obj) {
      state.dataTableDimensions = obj
    }
  },
  actions: {
    //check if the application is executing for the first time
    getAppData({ commit }) {
      return new Promise((resolve, reject) => {
        fb.appDataCollection.get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              commit('setAppData', { initiated: false });
              resolve('not initiated')
            }
            else {
              fb.appDataCollection.doc('config').get()
                .then(appData => {
                  commit('setAppData', appData.data());
                  resolve('fetched');
                })
            }
          })
          .catch(error => {
            reject(error.message);
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    initiateAppData() {
      return new Promise((resolve, reject) => {
        fb.appDataCollection.get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              fb.appDataCollection.doc('config').set({
                initiated: true,
                themes: {
                  light: {
                    primary: '#1976D2',
                    secondary: '#424242',
                    accent: '#82B1FF',
                    error: '#FF5252',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FFC107',
                    navBarColor: '#1976D2',
                  },
                }
              }).then(() => {
                resolve('initiated');
              })
            }
          })
      })
    },
    showSnackBar({ commit }, snackBar) {
      snackBar.show = true;
      snackBar.color = (snackBar.color === undefined) ? 'grey darken-2' : snackBar.color;
      snackBar.timeout = (snackBar.timeout === undefined) ? 5000 : snackBar.timeout;
      snackBar.icon = (snackBar.icon === undefined) ? 'mdi-information-outline' : snackBar.icon;
      commit('setSnackBar', snackBar);
    },
    showAnnouncement({ commit }, announcement) {
      announcement.show = true;
      announcement.color = (announcement.color === undefined) ? 'amber darken-1' : announcement.color;
      announcement.timeout = (announcement.timeout === undefined) ? 0 : announcement.timeout;
      announcement.icon = (announcement.icon === undefined) ? 'mdi-information-outline' : announcement.icon;
      commit('setAnnouncement', announcement);
    },
    calculateDimensions({ commit }, size) {
      commit('setDialogDimensions', {
        height: (window.innerHeight * 45) / 100,
        width: (window.innerWidth * 40) / 100
      })
      commit('setDimensions', {
        height: window.innerHeight,
        width: window.innerWidth
      })
      //commit('setWindowSize', this.$vuetify.breakpoint.name)
      switch (size) {
        case 'xs':
          commit('setDataTableDimensions', {
            height: (window.innerHeight * 45) / 100,
            width: (window.innerWidth * 40) / 100,
            itemsPerPage: 5
          })
          break;
        case 'sm':
          commit('setDataTableDimensions', {
            height: (window.innerHeight * 45) / 100,
            width: (window.innerWidth * 40) / 100,
            itemsPerPage: 5
          })
          break;
        case 'md':
          console.log('Data Table Dimensions: ', (window.innerHeight * 45) / 100)
          commit('setDataTableDimensions', {
            height: (window.innerHeight * 50) / 100,
            width: (window.innerWidth * 40) / 100,
            itemsPerPage: 5
          })
          break;
        case 'lg':
          commit('setDataTableDimensions', {
            height: (window.innerHeight * 60) / 100,
            width: (window.innerWidth * 40) / 100,
            itemsPerPage: 5
          })
          break;
        case 'xl':
          commit('setDataTableDimensions', {
            height: (window.innerHeight * 65) / 100,
            width: (window.innerWidth * 40) / 100,
            itemsPerPage: 10
          })
          break;
      }
    }

  }
}