import * as fb from "../../firebase";
export default {
    state: {
        clients: [],
        selectedClient: ''
    },
    getters: {
        clients : state => state.clients,
        selectedClient: state => state.selectedClient
    },
    mutations: {
        setClients(state, client){
            state.clients = client
        },
        setSelectedClient(state, client){
            state.selectedClient = client
        }
    },
    actions: {
        addClient({getters}, name){
            fb.clientCollection.add({clientName: name}).then(response => {
                console.log('Client Added')
            })
        },
        pullClients({ commit }){
            fb.clientCollection.onSnapshot(snapshot => {
                if(snapshot.empty) console.log('Empty Client collection');
                commit('setClients', snapshot.docs)
            })
        }
    }

}

