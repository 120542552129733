import * as fb from "../../firebase";
export default {
    state: {
        shows: [],
        selectedShow: null
    },
    getters: {
        shows: state => state.shows,
        selectedShow: state => state.selectedShow
    },
    mutations: {
        setShows(state, show) {
            state.shows = show
        },
        setSelectedShow(state, show) {
            state.selectedShow = show
        }
    },
    actions: {
        getShows({ commit }, client) {
            console.log('Inside Shows: ', client)
            fb.showCollection.where('clientName', '==', client).onSnapshot(snapshot => {
                commit('setShows', snapshot.docs)
            })
        },

        requestShow({ commit }, show) {
            fb.showCollection.add(show).then(response => {
                console.log('SHow Requested')
            })
        },

        approveShow({ dispatch }, show) {
            // create license 
            // We will be only charging for Kiosk licenses, User licenses are for free
            // Change the status to upcoming 
            dispatch('generateKioskLicenses', show.data()).then(() => {
                dispatch('updateShowToUpcoming', show)
            })
        },

        updateShowToUpcoming({ commit }, show) {
            fb.showCollection.doc(show.id).update({
                status: 'FUTURE'
            })
        }

    }

}

