import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { auth } from './firebase/index';
import { mapGetters } from 'vuex';
import axios from 'axios';
Vue.config.productionTip = false;
let app;

auth.onAuthStateChanged(user => {
  if (!app) {

    app = new Vue({
      router,
      store,
      vuetify,
      render: function (h) { return h(App) },
      // add next tick to pass telepresence
      data() {
        return {
          telepresence: undefined,
          count: 1
        }
      },
      computed: {
        ...mapGetters(["getUserCallStatus", "userProfile"])
      },
      watch: {
        userProfile(newVal) {
          if (newVal.data().onlineStatus === 'Available' && this.count === 1) {
            store.dispatch("pullCallHistory");
            store.dispatch("handleIncomingRequest")
            store.dispatch("handleGroupIncomingRequest")
            //this.createStripCustomer()

            this.count = 2;
          }
        }
      },
      methods: {
        createStripCustomer() {
          const config = {
            method: 'post',
            url: 'https://us-central1-fir-dashboard-d788d.cloudfunctions.net/apiV2/createStripeCustomer/a763cd056f1b2405788443b7197e0708',
          }
          axios(config)
            .then(result => {
              console.log('Result from axios call', result)
              if (result.status === 201) {
                // call the next cloud function to add subscription to the customer
                this.addSubscription(result.data)
              }
            })
            .catch(error => {
              console.log('Error : ', error.message)

            })
        },
        addSubscription(cust_id) {
          const config = {
            method: 'post',
            url: 'https://us-central1-fir-dashboard-d788d.cloudfunctions.net/apiV2/addSubscription/a763cd056f1b2405788443b7197e0708',
            params: { customerID: cust_id, productID: 'price_1KlgKbEV5PWIaZOvlu4nsLkn' }
          }
          axios(config)
            .then(result => {
              console.log('Result from axios call', result)
            })
            .catch(error => {
              console.log('Error : ', error.message)

            })
        }
      }



    }).$mount('#app')
  }
  if (user) {
    store.dispatch('fetchUserProfile', user).then(() => {
      console.log('Kiosk Profile has been fetched')
      setTimeout(() => {
        console.log('Set Timeout executed')
        store.dispatch('manageUserPresence', user);
      }, 1500)
    })
  }
})




