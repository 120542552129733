import * as fb from "../../firebase";

export default {
  state: {
    registeredUsers: [],
    invitedUsers: [],
    registeredUsersListener: null,
    invitedUsersListener: null
  },
  getters: {
    registeredUsers: state => state.registeredUsers,
    invitedUsers: state => state.invitedUsers,
    registeredUsersListener: state => state.registeredUsersListener,
    invitedUsersListener: state => state.invitedUsersListener
  },
  mutations: {
    setRegisteredUsers(state, users) {
      state.registeredUsers = users;
    },

    setInvitedUsers(state, users) {
      state.invitedUsers = users;
    }
  },
  actions: {
    // For Super Admin
    // Pull Invited and Registered User for specific client
    // Query will be made on the basis of the input
    pullUserDataSuperAdmin({ commit, state }, client) {
      // Pull both registered and invited users
      state.registeredUsersListener = fb.userCollection.where('clientName', '==', client).onSnapshot(snapshot => {
        console.log('Registered User: ', snapshot.docs)
        commit('setRegisteredUsers', snapshot.docs)
      })

      state.invitedUsersListener = fb.invitedUsersCollection.where('clientName', '==', client).onSnapshot(snapshot => {
        console.log('Invited User: ', snapshot.docs)
        commit('setInvitedUsers', snapshot.docs)
      })
    },

    detachListener({ getters }) {
      getters.registeredUsersListener();
      getters.invitedUsersListener();
    },

    fetchInvitedUserFromLicense({ getters }, license) {
      return new Promise((resolve, reject) => {
        console.log('License to look for: ', license)
        fb.invitedUsersCollection.where("licenseNumber", "==", license.licenseNumber).get().then(user => {
          resolve(user.docs)
        })
      })
    },

    pullUserShow({ commit }, showName) {
      fb.userCollection.where("showName", "==", showName).onSnapshot(snapshot => {
        commit('setRegisteredUsers', snapshot.docs)
      })
    },

    // Need to work on below

    pullUser({ commit, getters }, userId) {
      return new Promise((resolve, reject) => {
        let exists = getters.allRegisteredUsers.some(element => element.id === userId)
        console.log('Exists: ', exists, userId)
        if (exists) {
          //
        } else {
          fb.userCollection.doc(userId).onSnapshot(snapshot => {
            let index = getters.allRegisteredUsers.findIndex(element => element.id === snapshot.id)
            if (index !== -1) {
              getters.allRegisteredUsers.splice(index, 1)
              commit('setRegisteredUsers', snapshot)
            } else {
              commit('setRegisteredUsers', snapshot)
            }
            resolve('User Pulled')
          })
        }
      })
    },

    getAllInvitedUsers({ commit }) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.onSnapshot(snapshot => {
          if (snapshot.empty) console.log('Empty user collection');
          commit('setAllInvitedUsers', snapshot.docs);
          resolve('Fetched all Invited Users in collection');
        }, error => {
          reject(error.message);
        })

      })
    },
    updateRegisteredUserInformation({ state }, userData) {
      return new Promise((resolve, reject) => {
        fb.userCollection.doc(userData.id).update({
          name: userData.name,
          userRole: userData.userRole,
          email: userData.email
        })
          .then(() => {
            resolve('User Information updated successfully.')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    deleteRegisteredUser({ state }, userId) {
      return new Promise((resolve, reject) => {
        fb.userCollection.doc(userId).delete()
          .then(() => {
            resolve('User deleted successfully')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    updateInvitedUserInformation({ state }, userData) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.doc(userData.id).update({
          name: userData.name,
          userRole: userData.userRole,
          email: userData.email,
          assignedKiosks: userData.assignedKiosks
        })
          .then(() => {
            resolve('User Information updated successfully.')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    deleteInvitedUser({ state }, userId) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.doc(userId).delete()
          .then(() => {
            resolve('User deleted successfully')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },

    removeUserFromGroup({ getters }, user) {
      if (getters.userProfile.data().groups.includes(user.groupName)) {
        console.log('remove user from group called')
        return new Promise((resolve, reject) => {
          fb.userCollection.doc(user.userId).update({
            groups: fb.firebase.firestore.FieldValue.arrayRemove(
              user.groupName
            )
          }).then(() => {
            resolve('User updated')
          })
            .catch(error => {
              reject(error.message)
            })
        })
      }

    },

    assignLicenseNumber({ getters }, license) {
      return new Promise((resolve, reject) => {
        fb.userCollection.doc(getters.userProfile.id).update({
          licenseNumber: license,
          telepresence: true
        }).then(() => {
          resolve('User updated with license')
        })
          .catch(error => {
            reject(error.message)
          })
      })
    },

    addUserLicenseNumber({ getters }, license) {
      return new Promise((resolve, reject) => {
        fb.userCollection.doc(getters.userProfile.id).update({
          userLicense: fb.firebase.firestore.FieldValue.arrayUnion(
            license
          )
        }).then(() => {
          resolve('User updated with license')
        })
          .catch(error => {
            reject(error.message)
          })
      })
    }
  }
}