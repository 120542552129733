import * as fb from "../../firebase";
export default {
    state: {
        loadInitiated: false
    },
    getters: {
        loadInitiated: state => state.loadInitiated
    },
    mutations: {
        setLoadInitiated(state, status) {
            state.loadInitiated = status
        }
    },
    actions: {
        loadAdminData({ dispatch, getters }) {
            dispatch('getShows', getters.userProfile.data().clientName).then(() => {
                dispatch('pullLicenses')
            })
        },
        loadSuperAdminData({ dispatch }) {
            dispatch('pullClients')
        },
        pullLicenses({ dispatch, getters }) {
            dispatch('pullKioskLicenses', getters.userProfile.data().clientName).then(() => {
                dispatch('pullUserLicenses', getters.userProfile.data().clientName).then(() => {
                    console.log('Kiosk and User Licenses pulled.')
                })
            })
        }
    }
}

