import * as fb from "../../firebase";
import router from "../../router";
import store from "../index";
import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import moment from "moment";
export default {
  state: {
    invitedUserProfile: {},
    userProfile: {},
    userId: null,
    token: null,
    activeSession: 0,
    admin: false,
    verfied: false
  },
  getters: {
    userProfile: state => state.userProfile,
    invitedUserProfile: state => state.invitedUserProfile,
    userId: state => state.userId,
    getActiveSession: state => state.activeSession,
    isAdmin: state => state.superAdmin,
    verified: state => state.verified
  },
  mutations: {
    setUserProfile(state, data) {
      state.userProfile = data;
    },
    setInvitedUserProfile(state, data) {
      state.invitedUserProfile = data;
    },
    setUserId(state, uid) {
      state.userId = uid;
    },
    setToken(state, token) {
      state.token = token
    },
    setActiveSession(state, count) {
      state.activeSession = count
    },
    setAdmin(state, status) {
      state.superAdmin = status
    },
    setVerified(state, status) {
      state.verfied = status
    }
  },
  actions: {
    login({ dispatch }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.signInWithEmailAndPassword(form.email, form.password).then(cred => {
          //fetch user profile
          dispatch('fetchUserProfile', cred.user);
          resolve('Successfully Logged In');
        })
          .catch(error => {
            reject(error.message)
          })
      })
    },
    logout({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('setUserUnavailable');
        fb.auth.signOut()
          .then(() => {
            commit('setUserProfile', {});
            commit('setUserId', null);
            router.push('/login');
            resolve('Successfully Logged Out')
            commit('setVerified', false)
            location.reload();
          })
          .catch(error => {
            reject(error.message);
          })

      })
    },
    createAdminAccount({ dispatch }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.createUserWithEmailAndPassword(form.email, form.password)
          .then(cred => {
            //create user profile in usercollection
            fb.userCollection.doc(cred.user.uid).set({
              name: form.name,
              email: form.email,
              userRole: form.userRole,
              createdOn: new Date(),
              onlineStatus: 'Unavailable',
              show: null
            }).then(() => {
              dispatch('initiateAppData')
                .then(() => {
                  resolve('Account Created Successfully.');
                })
            })
          })
          .catch(error => {
            reject(error.message);
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },

    // Update Password Action. It is called inside Profile Menu
    updatePassword({ getters }, newPassword) {
      const user = fb.auth.currentUser;
      user.updatePassword(newPassword).then(() => {
        console.log('Password updated successfully')
      }).catch((error) => {
        console.log(error.message)
      });
    },


    fetchUserProfile({ getters, dispatch }, user) {
      fb.userCollection.doc(user.uid)
        .onSnapshot(userData => {
          if (userData.data() === undefined) {
            dispatch('logout')
          } else {
            if (!getters.verified) {
              dispatch('verifyUser', {
                id: user.uid,
                userData: userData
              })
            }
          }
        });
    },
    inviteNewUser({ dispatch }, user) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.add({
          name: user.name,
          email: user.email,
          userRole: user.userRole,
          clientName: user.clientName,
          fdn: user.fdn,
          licenseNumber: user.licenseNumber,
          createdOn: new Date(),
          showName: user.showName
        }).then(response => {
          //dispatch('setUserLicense', user.licenseNumber)
          resolve('Invitation Sent Successfully')
          //resolve({ message: 'Invitation sent successfully.', id: response.id, userInfo: response.data() })
          //resolve('Invitation sent successfully.');
          // update license to inUse

        })
          .catch(error => {
            console.log('Error at invite user: ', error.message)
            reject(error.message);
          })

      })
    },
    getInvitedUserInformation({ commit }, uId) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.doc(uId).get()
          .then(userData => {
            commit('setInvitedUserProfile', userData.data());
            resolve('Fetched User Information');
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },

    verifyUser({ commit }, user) {
      // Super Admin and Admin will not have telepresence feature available to them.
      // For rest, license is to be verified
      if (['super admin', 'admin'].includes(user.userData.data().userRole.toLowerCase())) {
        commit('setUserProfile', user.userData);
        commit('setUserId', user.id);
        commit('setVerified', true)
        if(router.currentRoute.path !== '/'){
          router.push('/')
        }
      } else {
        fb.userLicenseCollection.doc(user.userData.data().licenseNumber).onSnapshot(license => {

          let currentDate = moment(new Date()).format("L")
          let licenseStartDate = moment(license.data().startDate.toDate()).format("L")
          let licenseEndDate = moment(license.data().expiryDate.toDate()).format("L")

          if (moment(currentDate).isBetween(licenseStartDate, licenseEndDate) || moment(currentDate).isBefore(licenseStartDate)) {
            commit('setUserProfile', user.userData);
            commit('setUserId', user.id);
            commit('setVerified', true)
            if (router.currentRoute.path === '/login' || router.currentRoute.path === '/admin') {
              if(router.currentRoute.path !== '/'){
                router.push('/')
              }
            }
          } else {
            commit('setLicenseExpiry', true)
          }
        })
      }
    },
    createAccount({ commit, dispatch }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.createUserWithEmailAndPassword(form.email, form.password)
          .then(cred => {
            //create user profile in usercollection
            fb.userCollection.doc(cred.user.uid).set({
              name: form.name,
              email: form.email,
              userRole: form.userRole,
              licenseNumber: form.licenseNumber,
              telepresence: true,
              createdOn: new Date(),
              onlineStatus: 'Unavailable',
              onCall: false,
              clientName: form.clientName,
              showName: form.showName
            }).then(() => {
              //update license with User Information
              dispatch('updateUserLicenseToRegistered', form.licenseNumber)
              fb.invitedUsersCollection.doc(form.invitationId).delete()
                .then(() => {
                  fb.auth.signOut();
                  commit('setUserProfile', {});
                  commit('setUserId', null);
                  resolve('Account Created Successfully. Login to Continue');
                  router.push('/login');
                })
            })
          })
          .catch(error => {
            reject(error.message);
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    setUserAvailable({ state }) {
      fb.userCollection.doc(state.userId).update({
        onlineStatus: 'Available',
      })
    },
    setUserOffCall({ state, getters }) {
      fb.userCollection.doc(state.userId).update({
        onlineStatus: 'Available',
        onCall: false
      })
    },
    setUserBusy({ state }) {
      fb.userCollection.doc(state.userId).update({
        onlineStatus: 'Busy',
      })
    },
    setUserOnCall({ state, getters }) {
      fb.userCollection.doc(state.userId).update({
        onlineStatus: 'Busy',
        onCall: true
      })
    },
    setUserUnavailable({ state }) {
      fb.userCollection.doc(state.userId).update({
        onlineStatus: 'Unavailable',
      })
    },
    manageUserPresence({ getters, dispatch }, user) {
      var userStatusDatabaseRef = fb.firebase.database().ref('/status/' + user.uid);
      var myStorage = window.sessionStorage;
      if (getters.userProfile === undefined) {
        dispatch('logout')
      } else {
        if (getters.userProfile.data().onlineStatus === 'Busy') {
          var isOnlineForDatabase = {
            state: 'Busy',
            last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
          };
        } else {
          isOnlineForDatabase = {
            state: 'Available',
            last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
          };
        }
      }

      // for device fingerprinting
      // const fpPromise = FingerprintJS.load({
      //   apiKey: "NdaNPelRK4A5SKuN1M2y"
      // })

      // fpPromise
      //   .then(fp => fp.get())
      //   .then(result => console.log(result))

      fb.firebase.database().ref('.info/connected').on('value', function (snapshot) {
        console.log('Snap shot value: ', snapshot.val())
        // If we're not currently connected, don't do anything.
        if (snapshot.val() === false) return;
        userStatusDatabaseRef.push(isOnlineForDatabase).then(response => {
          myStorage.setItem('sessionID', response.key.toString());
          var userStatusDatabaseRefDel = fb.firebase.database().ref('/status/' + user.uid + '/' + response.key);
          userStatusDatabaseRefDel.onDisconnect().remove()
        })
      });

    }
  } // Actions
} // Main end
