<template>
    <v-footer class="main">
        <v-col
                cols="12"
                class="text-center grey--text">
            {{new Date().getFullYear()}} — <strong>ARSconnect </strong>
            <span class="text-caption">
                  (Beta)
              </span>
              <span> Window Size:  {{ windowSize }} </span>
              <span> Height:  {{ windowDimensions.height }} , width: {{ windowDimensions.width }} </span>
        </v-col>
    </v-footer>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
      name: 'dasfooter',
      computed:{
        ...mapGetters(['windowSize', 'windowDimensions'])
      }
    }
</script>