import * as fb from '../../firebase/index.js'
import router from "../../router";
import axios from 'axios';
export default {
    state: {
        incomingCallDialog: {
            show: false,
            meeting: []
        },
        incomingCallCount: 0,
        incomingCallListener: undefined,
        incomingGroupCallListener: undefined,
        ringer: new Audio('https://cms.ottawa.arsconnect.com/uploads/ring_7a6e3357c1.mp3')
    },
    getters: {
        getIncomingCallDialog: state => state.incomingCallDialog,
        getIncomingCallCount: state => state.incomingCallCount,
        getIncomingCallListener: state => state.incomingCallListener,
        getIncomingGroupCallListener: state => state.incomingGroupCallListener
    },
    mutations: {
        setIncomingCallCount(state, value) {
            state.incomingCallCount = value
        },

        setIncomingCallDialog(state, obj) {
            console.log('At incoming call dialog: ', obj)
            state.incomingCallDialog.show = obj.show
            if (state.incomingCallDialog.meeting !== []) {
                state.incomingCallDialog.meeting.push(obj.meeting)
            } else {
                state.incomingCallDialog.meeting = []
            }
        },

        closeIncomingCallDialog(state) {
            state.incomingCallDialog.show = false
            state.incomingCallDialog.meeting = []
        }
    },
    actions: {
        acceptCallRequest({ dispatch, getters, commit }, meet) {
            console.log('Accepting incoming Call: ', meet)
            commit('setOutgoingCall', meet.meetingId)
            commit('setMeetingCode', meet.meetingCode)

            commit("setMeetingUrl", {
                meetingCode: meet.meetingCode,
                meetingId: meet.meetingId,
                initiator: meet.initiatorName
            });
            if (router.currentRoute.path !== "/liveCall") {
                setTimeout(() => {
                    router.push('/liveCall')
                }, 500)
            }
            dispatch('setUserOnCall')
            if (meet.callType === "Group") {
                fb.telepresenceCollection
                    .doc(meet.meetingId)
                    .update({
                        callStatus: "inProgress",
                        conferenceAttendees: fb.firebase.firestore.FieldValue.arrayUnion(
                            getters.userId
                        ),
                        // requestedUser: fb.firebase.firestore.FieldValue.arrayRemove(
                        //     getters.userId
                        // ),
                        requestedOrg: null
                    }).then(() => {
                        console.log('Record updated successfully.')
                        commit('setIncomingCallCount', getters.getIncomingCallCount - 1)
                    });
            } else {
                fb.telepresenceCollection
                    .doc(meet.meetingId)
                    .update({
                        callStatus: "inProgress",
                        conferenceAttendees: fb.firebase.firestore.FieldValue.arrayUnion(
                            getters.userId
                        ),
                        requestedUser: [getters.userId],
                        requestedOrg: null
                    }).then(() => {
                        console.log('Record updated successfully.')
                        commit('setIncomingCallCount', getters.getIncomingCallCount - 1)
                    });
            }
        },

        rejectGroupCall({ getters, commit }, meet) {
            console.log('Meet at reject Group Call: ', meet)
            commit('setIncomingCallCount', getters.getIncomingCallCount - 1)
            if (meet.meetCount === 0) {
                commit("closeIncomingCallDialog");
            }

            console.log('Meet at reject Group Call: ', meet.callType)
            if (meet.meetInfo.callType === "Group") {
                console.log('Coming in here')
                fb.telepresenceCollection
                    .doc(meet.meetInfo.meetingId)
                    .update({
                        requestedUser: fb.firebase.firestore.FieldValue.arrayRemove(
                            getters.userId
                        ),
                    }).then(() => {
                        console.log('Record updated successfully.')
                    });
            }
            const config = {
                method: 'post',
                url: 'https://us-central1-telepresence-service.cloudfunctions.net/apiV2/getCallInfo/a763cd056f1b2405788443b7197e0708',
                params: { call: meet.meetInfo.meetingId, action: 'reject', user: getters.userId }
            }
            axios(config)
                .then(result => {
                    console.log('Result from axios call', result)
                })
                .catch(error => {
                    console.log('Error : ', error.message)

                })
        },

        rejectCallRequest({ commit, getters }, meet) {
            console.log('Reject Single Call')
            if (meet.meetCount === 0) {
                commit("closeIncomingCallDialog");
            }
            fb.telepresenceCollection
                .doc(meet.meetInfo.meetingId)
                .update({
                    callStatus: "Declined",
                    conferenceAttendees: fb.firebase.firestore.FieldValue.arrayUnion(
                        getters.userId
                    ),
                    requestedUser: null
                })
        },
        endCallRequest({ commit }, id) {
            fb.telepresenceCollection
                .doc(id)
                .update({
                    callStatus: "Ended",
                    endTime: new Date(),
                    requestedUser: null,
                    requestedOrg: null
                });
        },


        handleIncomingRequest({ commit, getters, dispatch, state }) {
            state.incomingCallListener = fb.telepresenceCollection
                .where(
                    "requestedUser",
                    "array-contains",
                    getters.userId
                ).where("callType", "in", ["Single", "Pull"])
                .onSnapshot((calls) => {
                    console.log('Calls: ', calls)
                    // Session ID is set in Manage user presence in auth.js
                    let sessionID, broswerSessionID;
                    if (calls.docs.length > 0) {

                        if (getters.userProfile.data().activeSession.length > 0) {
                            sessionID = getters.userProfile.data().activeSession[0].toString()
                        }
                        broswerSessionID = window.sessionStorage.getItem('sessionID').toString()
                    }
                    if (sessionID === broswerSessionID && (getters.userProfile.data().onlineStatus === "Available" || getters.userProfile.data().onCall)) {
                        // Condition passed to recieve the call
                        calls.docChanges().forEach(change => {
                            if (change.type === 'removed') {
                                state.ringer.pause();
                                console.log('Coming here at removed')
                                console.log('Get total incoming calls at count: ', getters.getIncomingCallCount)
                                if (getters.getIncomingCallCount === 1) {
                                    commit('setIncomingCallCount', getters.getIncomingCallCount - 1)
                                    if (getters.getIncomingCallDialog.show) {
                                        commit("setCallStatusDialog", {
                                            show: true,
                                            message: 'Missed Call from ' + change.doc.data().callInitiator.name,
                                        })

                                        commit("closeIncomingCallDialog");
                                    }
                                } else {
                                    if (getters.getIncomingCallCount !== 0) {
                                        commit('setIncomingCallCount', getters.getIncomingCallCount - 1)
                                    }
                                    var index = getters.getIncomingCallDialog.meeting.findIndex(
                                        (meeting) => meeting.meetingId === change.doc.id
                                    );
                                    if (index !== -1) {
                                        console.log('Index', index)
                                        getters.getIncomingCallDialog.meeting.splice(index, 1);
                                    }

                                }
                            } else if (change.type === 'added') {
                                console.log('Get total incoming calls at count: ', getters.getIncomingCallCount)
                                commit('setIncomingCallCount', getters.getIncomingCallCount + 1)

                                dispatch('callHandlingIncoming', change.doc)
                                state.ringer.currentTime = 0
                                state.ringer.loop = true
                                try {
                                    state.ringer.play();
                                } catch (e) {
                                    console.log(e.message)
                                }

                                // Adding notification here
                                if (!("Notification" in window)) {
                                    alert("This browser does not support desktop notification");
                                }
                                else if (Notification.permission === "granted") {
                                    var notification = new Notification('Incoming Call Ottawa Dashboard', { body: 'Receiving Call from ' + change.doc.data().callInitiator.name })
                                }
                                else if (Notification.permission !== "denied") {
                                    Notification.requestPermission().then(function (permission) {
                                        if (permission === "granted") {
                                            var notification = new Notification('Incoming Call Ottawa Dashboard', { body: 'Receiving Call from ' + change.doc.data().callInitiator.name })
                                        }
                                    });
                                }

                                notification.onclick = function (event) {
                                    window.parent.parent.focus();
                                }

                            } else {
                                // if (getters.getIncomingCallCount !== 0) {
                                //     commit('setIncomingCallCount', getters.getIncomingCallCount - 1)
                                // }
                                state.ringer.pause();
                                dispatch('callHandlingIncoming', change.doc)
                            }
                        })
                    }


                });
        },

        handleGroupIncomingRequest({ commit, getters, dispatch }) {
            let incomingTimeOut = 0;
            fb.telepresenceCollection
                .where(
                    "requestedUser",
                    "array-contains",
                    getters.userId
                ).where("callType", "==", "Group")
                .onSnapshot((calls) => {

                    // Session ID is set in Manage user presence in auth.js
                    let sessionID;
                    if (getters.userProfile.data().activeSession.length > 0) {
                        sessionID = getters.userProfile.data().activeSession[0].toString()
                    }
                    // = getters.userProfile.data().activeSession[0].toString()
                    let broswerSessionID = window.sessionStorage.getItem('sessionID').toString()

                    if (sessionID === broswerSessionID && (getters.userProfile.data().onlineStatus === "Available" || getters.userProfile.data().onCall)) {
                        // Condition passed to recieve the call
                        calls.docChanges().forEach(change => {
                            if (change.type === 'removed') {
                                if (getters.getIncomingCallCount === 1) {
                                    commit('setIncomingCallCount', getters.getIncomingCallCount - 1)
                                    if (getters.getIncomingCallDialog.show) {
                                        commit("setCallStatusDialog", {
                                            show: true,
                                            message: 'Missed Call from ' + change.doc.data().callInitiator.name,
                                        })

                                        commit("closeIncomingCallDialog");
                                    }
                                } else {
                                    if (getters.getIncomingCallCount !== 0) {
                                        commit('setIncomingCallCount', getters.getIncomingCallCount - 1)
                                    }
                                    var index = getters.getIncomingCallDialog.meeting.findIndex(
                                        (meeting) => meeting.meetingId === change.doc.id
                                    );
                                    if (index !== -1) {
                                        console.log('Index', index)
                                        getters.getIncomingCallDialog.meeting.splice(index, 1);
                                    }
                                    if (getters.getIncomingCallCount === 0) {
                                        commit("closeIncomingCallDialog");
                                    }


                                }
                            } else if (change.type === 'added') {
                                commit('setIncomingCallCount', getters.getIncomingCallCount + 1)
                                console.log('Get total incoming calls at count: ', getters.getIncomingCallCount)
                                dispatch('callHandlingIncoming', change.doc)
                                incomingTimeOut = setTimeout(function () {
                                    fb.telepresenceCollection
                                        .doc(change.doc.id)
                                        .update({
                                            requestedUser: fb.firebase.firestore.FieldValue.arrayRemove(
                                                getters.userId
                                            ),
                                        }).then(() => {
                                            console.log('Record updated successfully.')
                                        });
                                }, 10000)

                                // Initiate cloud function
                            } else {
                                clearTimeout(incomingTimeOut)
                                if (change.doc.data().callStatus !== 'Initiated') {
                                    dispatch('callHandlingIncoming', change.doc)
                                }

                            }
                        })
                    }
                });
        },

        // The method is used for both Caller and Callee
        // The method handles every scenario -> operations are performed considering both the scenarios

        callHandlingIncoming({ commit, dispatch, getters }, callStatus) {
            console.log('Call Details: ', callStatus.data())
            // Handling Call Status scenarios
            switch (callStatus.data().callStatus) {
                case 'Initiated':
                    commit('setIncomingCallDialog', {
                        show: true,
                        meeting: {
                            callerInfo: callStatus.data().callerInfo.name,
                            meetingCode: callStatus.data().meetingCode,
                            meetingId: callStatus.id,
                            initiatorName: callStatus.data().callInitiator.name,
                            callType: callStatus.data().callType,
                            addToCall: false
                        }
                    })
                    break;
                case 'inProgress':
                    if (callStatus.data().callType !== 'Group') {
                        commit("closeIncomingCallDialog");
                    }

                    break
                default:
                //
            }
        },


    }
}