import axios from 'axios';
import * as fb from "../../firebase";
export default {
    state: {
        stripe: require('stripe')('sk_test_51KYufDEV5PWIaZOvtUTl5a0ALj9vVMo2uGQhloOrlXqKvwtFGgQSiUuUaZNIqr82jmDSXti3HzRiYbFDGrsGMGDd00PMG5gK4X')
    },
    getters: {
        getStripe: state => state.stripe
    },
    mutations: {

    },
    actions: {
        createStripeCustomer({ dispatch }, kiosk) {
            const config = {
                method: 'post',
                url: 'https://us-central1-fir-dashboard-d788d.cloudfunctions.net/apiV2/createStripeCustomer/a763cd056f1b2405788443b7197e0708',
                params: {
                    name: kiosk.name,
                    email: kiosk.email,
                    description: kiosk.id
                }
            }
            axios(config)
                .then(result => {
                    console.log('Result from axios call', result)
                    if (result.status === 201) {
                        // call the next cloud function to add subscription to the customer
                        //dispatch('addFirstInvoice', result.data)
                        dispatch('addSubscription', { cust_id: result.data, kiosk_id: kiosk.id })
                    }
                })
                .catch(error => {
                    console.log('Error : ', error.message)

                })
        },
        addFirstInvoice({ dispatch }, cust_id) {
            const config = {
                method: 'post',
                url: 'https://us-central1-fir-dashboard-d788d.cloudfunctions.net/apiV2/oneTimeInvoice/a763cd056f1b2405788443b7197e0708',
                params: {
                    priceID: 'price_1Km1mwEV5PWIaZOv8WAMh0HK',
                    customerID: cust_id,
                }
            }
            axios(config)
                .then(result => {
                    if (result.status === 201) {
                        // call the next cloud function to add subscription to the customer
                        dispatch('addSubscription', cust_id)
                    }
                })
                .catch(error => {
                    console.log('Error : ', error.message)

                })
        },
        addSubscription({ dispatch }, detail) {
            const config = {
                method: 'post',
                url: 'https://us-central1-fir-dashboard-d788d.cloudfunctions.net/apiV2/addSubscription/a763cd056f1b2405788443b7197e0708',
                params: { customerID: detail.cust_id, priceFlatID: 'price_1Km1mwEV5PWIaZOv8WAMh0HK', priceSecondsID: 'price_1Km1qBEV5PWIaZOvN1UHJXnw' }
            }
            axios(config)
                .then(result => {
                    console.log('Result from axios call', result)
                    fb.kioskCollection.doc(detail.kiosk_id).update({
                        telepresenceSubscription: {
                            subscribed: true,
                            customerID: detail.cust_id,
                            subscriptionID: result.data
                        }
                    })
                })
                .catch(error => {
                    console.log('Error : ', error.message)

                })
        },
        onThegoPrice(cust_id) {
            const config = {
                method: 'post',
                url: 'https://us-central1-fir-dashboard-d788d.cloudfunctions.net/apiV2/addSubscription/a763cd056f1b2405788443b7197e0708',
                params: { customerID: cust_id, priceFlatID: 'price_1Km1mwEV5PWIaZOv8WAMh0HK', priceSecondsID: 'price_1Km1qBEV5PWIaZOvN1UHJXnw' }
            }
        }
    }

}

// const invoiceItem = await stripe.invoiceItems.create({
//     price: 'price_CBb6IXqvTLXp3f',
//     customer: 'cus_4fdAW5ftNQow1a',
//   });