<template>
  <v-app class="main" v-resize="onResize">
    <navigation v-if="dataLoaded && !isGuest"> </navigation>
    <v-main>
      <router-view v-if="showContent && dataLoaded"> </router-view>
      <loading v-else> </loading>
    </v-main>
    <dashFooter></dashFooter>
    <snackBar></snackBar>
    <announcement></announcement>
    <incoming-call-dialog></incoming-call-dialog>
    <outgoing-call-dialog></outgoing-call-dialog>
    <call-status-dialog></call-status-dialog>
    <license-error></license-error>
    <license-expired></license-expired>
  </v-app>
</template>

<script>
import snackBar from "@/components/notifications/snackBar.vue";
import announcement from "@/components/notifications/announcement.vue";
import navigation from "@/components/navigation/navigation.vue";
import loading from "@/components/loading.vue";
import dashFooter from "@/components/footer.vue";
import { mapGetters } from "vuex";
import IncomingCallDialog from "./components/telepresence/incomingCallDialog.vue";
import OutgoingCallDialog from "./components/telepresence/outgoingCallDialog.vue";
import CallStatusDialog from "./components/telepresence/callStatusDialog.vue";
import telepresence from "./components/telepresence.vue";
import licenseError from "./components/dialogs/license/licenseError.vue";
import LicenseExpired from './components/dialogs/license/licenseExpired.vue';
import router from "./router";
export default {
  name: "App",
  components: {
    snackBar,
    navigation,
    loading,
    dashFooter,
    announcement,
    IncomingCallDialog,
    OutgoingCallDialog,
    CallStatusDialog,
    telepresence,
    licenseError,
    LicenseExpired,
  },
  data() {
    return {
      showContent: false,
      isGuest: false,
      dataLoaded: false
    };
  },
  computed: {
    ...mapGetters([
      "userProfile",
      "appData",
      "allRegisteredUsers",
      "getUserCallStatus",
      "loadInitiated",
    ])
  },
  methods: {
    onResize() {
      if (window.innerWidth < 600) {
        this.$store.commit('setWindowSize', 'xs')
        this.$store.dispatch('calculateDimensions', 'xs')
      } else if (window.innerWidth > 600 && window.innerWidth < 960) {
        this.$store.commit('setWindowSize', 'sm')
        this.$store.dispatch('calculateDimensions', 'sm')
      } else if (window.innerWidth > 960 && window.innerWidth < 1264) {
        this.$store.commit('setWindowSize', 'md')
        this.$store.dispatch('calculateDimensions', 'md')
      } else if (window.innerWidth > 1264 && window.innerWidth < 1904) {
        this.$store.commit('setWindowSize', 'lg')
        this.$store.dispatch('calculateDimensions', 'lg')
      } else {
        this.$store.commit('setWindowSize', 'xl')
        this.$store.dispatch('calculateDimensions', 'xl')
      }
    }
  },
  beforeCreate() {
    this.$store.dispatch("getAppData").then(() => {
      if (this.appData.initiated) {
        this.$vuetify.theme.themes.light = this.appData.themes.light;
        if (router.currentRoute.meta.requiresGuest) {
          this.isGuest = true
          this.dataLoaded = true
        } else {
          this.isGuest = false
        }
      } else {
        this.dataLoaded = true
        this.isGuest = true
      }
      this.showContent = true;
    });
  },
  watch: {
    userProfile(newVal) {
      if (!this.loadInitiated) {
        this.isGuest = false
        this.dataLoaded = false
        this.$store.commit('setLoadInitiated', true)
      }
      if (!this.dataLoaded) {
        if (newVal.data().userRole.toLowerCase() === 'super admin') {
          this.$store.dispatch('loadSuperAdminData').then(() => {
            this.showContent = true;
            setTimeout(() => { this.dataLoaded = true; this.loading = false }, 1000)
          })
        }
        else if (newVal.data().userRole.toLowerCase() === "admin") {
          this.$store.dispatch('loadAdminData', newVal).then(() => {
            this.showContent = true;
            setTimeout(() => { this.dataLoaded = true; this.loading = false }, 1000)
          })
        } else {
          this.$store.dispatch('pullUserShow', newVal.data().showName).then(() => {
            this.showContent = true;
            setTimeout(() => { this.dataLoaded = true; this.loading = false }, 1000)
          })
        }
      }
    },
    mounted() {
      if (Notification.permission !== "granted") {
        Notification.requestPermission();
      }
      //window["console"]["log"] = function () { };
      console.warn = function () { };
      //this.$store.dispatch('sampleAPI')
    },
  }
}
</script>

<style>
.main {
  background-color: #f5f5f5 !important;
}

body::-webkit-scrollbar {
  display: none;
}

.v-card::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}

.v-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}

.v-data-table__wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}

.v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}

.miniplayer {
  z-index: 99999999 !important;
  padding-bottom: 30px;
  position: absolute;
  bottom: 1cm;
  right: 1cm;
}

/* .v-virtual-scroll::-webkit-scrollbar { */
/* -webkit-appearance: none; */
/* color: #6b1530; */
/* width: 10px; */
/* } */
/* .v-virtual-scroll::-webkit-scrollbar-thumb { */
/* border-radius: 2px; */
/* background-color: rgba(0, 0, 0, .5); */
/* -webkit-box-shadow: 0 0 1px #6b1530; */
/* } */
</style>
